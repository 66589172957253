import {
  Box,
  Divider,
  FormControlLabel,
  InfoCircleIcon,
  Stack,
  Toggle,
  Typography
} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import ColorSwatches from './ColorSwatches';
import SizeAvailabilityLink from './SizeAvailabilityLink';
import SizeList from './SizeList';

const StockIssueWarningMessageWrapper = styled(Box)({
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  borderRadius: 999,
  color: 'rgba(0, 0, 0, 0.57)',
  display: 'flex',
  padding: '0.1875rem 0.5rem'
});

const StyledDivider = styled(Divider)({
  '@media all and (min-width: 0)': {
    '&:last-child, &:nth-last-of-type(2):not(:nth-of-type(2))': {
      display: 'none'
    }
  }
});

export default function ProductColorsAndSizes({
  changeColor,
  colors,
  currentColor,
  hasSingles,
  preloadImage,
  renderSampleForm,
  singles,
  toggleSingles
}) {
  const [color, setColor] = useState(currentColor);
  const singlesEnabledColors = colors.filter((c) => c.singlesEnabled);
  const [swatchColors, setSwatchColors] = useState(
    hasSingles && singles ? singlesEnabledColors : colors
  );
  const oneSize = color.sizes[0]?.name === 'One Size';

  const youthSizes = useMemo(() => {
    return color.sizes.filter((size) => size.name.startsWith('Y'));
  }, [color]);

  const adultSizes = useMemo(() => {
    return color.sizes.filter((size) => !size.name.startsWith('Y'));
  }, [color]);

  useEffect(() => {
    if (
      hasSingles &&
      singles &&
      !currentColor.singlesEnabled &&
      !renderSampleForm
    ) {
      setColor(colors[0]);
      changeColor(colors[0]);
    }
    setSwatchColors(hasSingles && singles ? singlesEnabledColors : colors);
  }, [singles]);

  const handleColorChange = (c) => {
    preloadImage(c);
    setColor(c);
  };

  const renderColorSwatches = useMemo(
    () => (
      <ColorSwatches
        currentColor={currentColor}
        swatches={swatchColors}
        onSwatchClick={changeColor}
        onSwatchMouseEnter={handleColorChange}
        onSwatchMouseLeave={() => handleColorChange(currentColor)}
        onSwatchTouchStart={changeColor}
      />
    ),
    [swatchColors, currentColor]
  );

  const hasLowOnStock = color.stockIssues.includes('has_core_low');
  const hasOutOfStock = color.stockIssues.includes('has_core_out');
  const allSizesActive = useMemo(
    () =>
      youthSizes.every((size) => size.status === 'active') &&
      adultSizes.every((size) => size.status === 'active'),
    [youthSizes, adultSizes]
  );

  const noSizesIssues = !hasLowOnStock && !hasOutOfStock && allSizesActive;
  const showLowOnStockMessage = hasLowOnStock && !hasOutOfStock;

  return (
    <>
      <Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pb: {md: 0, sm: '0.5rem'}
          }}>
          <Typography variant="heading3" weight="bold">
            Colors:
          </Typography>
          {hasSingles && (
            <FormControlLabel
              labelPlacement="start"
              style={{marginRight: 0}}
              sx={{
                alignItems: 'center',
                mr: 0,
                paddingTop: 0,
                textAlign: 'end'
              }}
              control={
                <Toggle
                  data-testid="singles-toggle"
                  size="small"
                  checked={singles}
                  onChange={toggleSingles}
                  sx={{ml: '5px', mr: 0}}
                />
              }
              label="Ordering fewer than 6"
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
            margin: '0 -0.25rem',
            paddingTop: {md: '0.5rem', sm: 0}
          }}>
          {renderColorSwatches}
        </Box>
      </Box>
      <Stack
        gap={noSizesIssues ? '0.5rem' : '0.25rem'}
        pb="0.5rem"
        alignItems="flex-start"
        display="flex"
        flexDirection={noSizesIssues ? 'row' : 'column'}>
        <Stack gap="0.5rem" alignItems="center" direction="row" flexWrap="wrap">
          <Typography
            data-testid="current-color"
            fontSize="1rem"
            lineHeight="1.25rem">
            {color.name}
          </Typography>
          {!noSizesIssues && (
            <StockIssueWarningMessageWrapper>
              <InfoCircleIcon fontSize="inherit" sx={{mr: '0.25rem'}} />
              <Typography fontSize="0.875rem" lineHeight="125%">
                {showLowOnStockMessage
                  ? 'Some sizes are low on stock in this color.'
                  : 'Some sizes are out of stock in this color.'}
              </Typography>
            </StockIssueWarningMessageWrapper>
          )}

          {noSizesIssues && <StyledDivider orientation="vertical" flexItem />}
          <Box
            sx={{
              width: noSizesIssues ? 'auto' : '100%'
            }}>
            <SizeList
              compact={!hasOutOfStock && allSizesActive}
              sizes={color.sizes}
              oneSize={oneSize}
            />
          </Box>
          <SizeAvailabilityLink text="Check sizes for all colors" />
        </Stack>

        <Stack
          direction={noSizesIssues ? 'row' : 'column'}
          alignItems={noSizesIssues ? 'center' : 'flex-start'}
          flexWrap="wrap"
          gap={noSizesIssues ? '0.5rem' : '0.25rem'}
        />
      </Stack>
    </>
  );
}

ProductColorsAndSizes.propTypes = {
  changeColor: PropTypes.func.isRequired,
  colors: PropTypes.array.isRequired,
  currentColor: PropTypes.object.isRequired,
  hasSingles: PropTypes.bool.isRequired,
  preloadImage: PropTypes.func.isRequired,
  renderSampleForm: PropTypes.bool.isRequired,
  singles: PropTypes.bool.isRequired,
  toggleSingles: PropTypes.func.isRequired
};
