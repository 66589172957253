import {
  useImportedTrustPilotProductReviews,
  useTrustPilotProductReviews
} from './queries/trustPilotProductReviews';

export default function useTrustpilotReviewsCount(skuList) {
  const {
    data: trustPilotProductReviews,
    isError: isReviewError,
    isLoading: isReviewLoading
  } = useTrustPilotProductReviews(skuList);
  const {
    data: importedTrustPilotProductReviews,
    isError: isImportedReviewError,
    isLoading: isImportedReviewLoading
  } = useImportedTrustPilotProductReviews(skuList);

  const tpReviewCount = trustPilotProductReviews?.numberOfReviews?.total || 0;
  const importedTpReviewCount =
    importedTrustPilotProductReviews?.numberOfReviews?.total || 0;

  const totalTpReviewCount = tpReviewCount + importedTpReviewCount;

  const isLoading = isReviewLoading || isImportedReviewLoading;
  const isError = isReviewError || isImportedReviewError;

  return {
    importedTpReviewCount,
    isError,
    isLoading,
    totalTpReviewCount,
    tpReviewCount
  };
}
