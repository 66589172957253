import {
  Box,
  IconButton,
  Link,
  Tooltip,
  Typography,
  XCloseIcon
} from '@customink/pigment-react';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React, {useState} from 'react';
import {trackRequestSampleHoverEvent} from '../../utils/metrics/productDetailsMetrics';

const tooltipSx = () => ({
  '& .MuiTooltip-arrow': {
    color: 'white'
  },
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0 0 1rem rgba(0, 0, 0, 0.1)',
    color: '#444444',
    maxWidth: '380px',
    padding: '1rem',
    position: 'relative'
  }
});

const RequestSampleTooltip = ({children, ttarpNumber}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMobileBreakpoint();

  const handleOpen = () => {
    setOpen(true);
    trackRequestSampleHoverEvent();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIconButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleClose();
  };

  return (
    <Tooltip
      onOpen={handleOpen}
      onClose={() => !isMobile && handleClose()}
      open={open}
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.325rem',
            width: '100%'
          }}
          onClick={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}>
          {isMobile && (
            <IconButton
              fill
              aria-label="Close tooltip"
              onClick={handleIconButtonClick}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent'
                },
                position: 'absolute',
                right: '0.5rem',
                top: '0.5rem'
              }}>
              <XCloseIcon fontSize="inherit" />
            </IconButton>
          )}
          <Typography variant="bodyLong1">
            We know how important it is to find the right product style, fit,
            and color. Don&apos;t worry — we&apos;re here to help.
          </Typography>
          <Typography variant="bodyLong1">
            Call us at&nbsp;
            <Link href={`tel:+1-${ttarpNumber}`}>{ttarpNumber}</Link>
            &nbsp; and we will be happy to ship a sample to you, typically for a
            small fee plus shipping costs.
          </Typography>
          <Typography variant="bodyLong1">
            We&apos;re also available to answer any questions you may have about
            our products and can make recommendations based on your particular
            needs.
          </Typography>
        </Box>
      }
      PopperProps={{sx: tooltipSx}}
      placement="bottom"
      arrow>
      {children}
    </Tooltip>
  );
};

export default RequestSampleTooltip;
