import {Box, Typography} from '@customink/pigment-react';
import React, {useContext} from 'react';
import {PDP_RELATED_PRODUCTS_ENTITY_TYPE} from '../../constants/pdpRelatedProducts';
import useIntersectionObserverOnce from '../../hooks/useIntersectionObserverOnce';
import addColorToStylePath from '../../utils/addColorToStylePath';
import {
  fireGA4ClickEvent,
  fireGA4DisplayEvent
} from '../../utils/metrics/pdpRelatedStyles';
import CurrentSelectedColorContext from '../Context/colorContext';
import ProductDetailsRow from '../ProductDetailsRow';
import ProductDetailsCard from './RelatedProductCard';

const AlternativeProducts = ({alternativeProducts, similarRef}) => {
  const {setSimilarAccordionState, similarAccordionState} = useContext(
    CurrentSelectedColorContext
  );
  useIntersectionObserverOnce(similarRef, () =>
    fireGA4DisplayEvent({
      entityType: PDP_RELATED_PRODUCTS_ENTITY_TYPE.alternativeStyles
    })
  );

  const handleCardClick = (styleId) => {
    fireGA4ClickEvent({
      entityId: styleId,
      entityType: PDP_RELATED_PRODUCTS_ENTITY_TYPE.alternativeStyles
    });
  };

  return (
    <ProductDetailsRow
      childrenRef={similarRef}
      accordionSummary="Similar Styles"
      accordionExpanded={similarAccordionState}
      onAccordionClick={() => {
        setSimilarAccordionState(!similarAccordionState);
      }}
      title="Similar Styles">
      <Typography variant="bodyLong1" className="alternative-heading-body">
        Make sure you&apos;ve got enough for everyone with these styles that
        have a similar fit, feel, and color selection.
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: {md: '1rem', sm: '0.5rem'},
          gridTemplateColumns: {
            lg: 'repeat(4, 1fr)',
            md: 'repeat(auto-fill, minmax(200px, 1fr))',
            sm: 'repeat(auto-fill, minmax(280px, 1fr))'
          },
          mt: '1rem'
        }}>
        {alternativeProducts.map((alternative) => (
          <ProductDetailsCard
            key={`alternative-${alternative.name}`}
            complimentBadge={alternative.complement_badge}
            imageSrc={alternative.image_src}
            imageSrcSet={alternative.image_srcset}
            linkHref={addColorToStylePath(alternative.link, alternative.color)}
            minQty={alternative.min_qty}
            name={alternative.name}
            ratingCount={alternative.rating_count}
            ratingScore={alternative.rating_score}
            sizing={alternative.sizing}
            onClick={() => handleCardClick(alternative?.style_id)}
          />
        ))}
      </Box>
    </ProductDetailsRow>
  );
};

export default AlternativeProducts;
