import {useEffect, useRef} from 'react';

export default function useIntersectionObserverOnce(
  ref,
  onIntersection,
  options = {threshold: 0.6}
) {
  const hasTracked = useRef(false);

  useEffect(() => {
    if (!ref.current || hasTracked.current) return () => {};

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasTracked.current) {
          hasTracked.current = true;
          onIntersection();
        }
      });
    }, options);

    observer.observe(ref.current);

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
      observer.disconnect();
    };
  }, [ref, onIntersection, options]);
}
