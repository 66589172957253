import {Box, Divider, Link} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React from 'react';
import RequestSampleTooltip from './RequestSampleTooltip';

const SampleAndQuote = ({currentColor, ttarpNumber}) => {
  const handleSampleLink = () => {
    window.dataLayer.push({
      event: 'UA_Event',
      eventAction: 'sample request',
      eventCategory: 'product details page',
      eventLabel: 'hover'
    });
  };

  const handleQuoteLink = () => {
    window.dataLayer.push({
      click_section: 'pdp',
      event: 'quick_quote'
    });
    const popopts =
      'width=400,height=615,scrollbars=yes,toolbar=yes,menubar=no,location=yes,resizable=yes';
    const url = new URL(`${window.location.origin}/quotes`);
    url.searchParams.append('product_id', currentColor.legacyColorId);
    url.searchParams.append('condensed', 'true');
    window.open(url, 'quickquote', popopts);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
        justifyContent: {md: 'left', sm: 'flex-start'}
      }}>
      <RequestSampleTooltip ttarpNumber={ttarpNumber}>
        <span>
          <Link
            sx={{alignSelf: 'center', lineHeight: '1.5rem'}}
            variant="captionRegular"
            role="link"
            tabIndex="0"
            onClick={handleSampleLink}>
            Request a Sample
          </Link>
        </span>
      </RequestSampleTooltip>
      <Divider orientation="vertical" flexItem />
      <span>
        <Link
          data-testid="get-quote"
          sx={{lineHeight: '1.5rem'}}
          variant="captionRegular"
          role="link"
          tabIndex="0"
          onClick={handleQuoteLink}>
          Get a Quote
        </Link>
      </span>
    </Box>
  );
};

SampleAndQuote.propTypes = {
  currentColor: PropTypes.object,
  ttarpNumber: PropTypes.string
};

export default SampleAndQuote;
