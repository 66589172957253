export const COMPLEMENT_PRODUCTS_CLICK_LINK = 'complementary styles';
export const ALTERNATIVE_PRODUCTS_CLICK_LINK = 'similar styles';

export const PDP_RELATED_PRODUCTS_ENTITY_TYPE = {
  alternativeStyles: 'ALTERNATIVE_STYLES',
  complementaryStyles: 'COMPLEMENTARY_STYLES'
};

export const ENTIRY_TYPE_TO_CLICK_LINK = {
  [PDP_RELATED_PRODUCTS_ENTITY_TYPE.alternativeStyles]:
    ALTERNATIVE_PRODUCTS_CLICK_LINK,
  [PDP_RELATED_PRODUCTS_ENTITY_TYPE.complementaryStyles]:
    COMPLEMENT_PRODUCTS_CLICK_LINK
};
