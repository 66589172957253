import {Box, Rating, Typography} from '@customink/pigment-react';
import React, {useContext} from 'react';
import useTrustpilotReviewsCount from 'hooks/useTruspilotReviewsCount';
import CurrentSelectedColorContext from 'product_details_page/Context/colorContext';
import ProductContext from 'product_details_page/Context/productContext';
import {getQueryParam} from 'src/utils/url';
import {trackReadReviewsClickEvent} from 'utils/metrics/productDetailsMetrics';
import ProductHeaderBadges from './ProductHeaderBadges';
import ProductHeaderBadgesSkeleton from './ProductHeaderBadgesSkeleton';
import ProductHeaderReviews from './ProductHeaderReviews';
import useProductBadges from './useProductBadges';

export default function ProductHeader({reviewRef}) {
  const {
    categoryInfo,
    colors = [],
    id,
    name: styleName,
    primaryCategoryId,
    ratingCount,
    ratingValue
  } = useContext(ProductContext);

  const skuList = colors.map((color) => color.id);
  const {isLoading, totalTpReviewCount} = useTrustpilotReviewsCount(skuList);
  const {setReviewsAccordionState} = useContext(CurrentSelectedColorContext);

  const hasBadges = getQueryParam('bg') === '1';

  const {badges, isErrorBadgeInfo, isLoadingBadges} = useProductBadges({
    categoryId: primaryCategoryId,
    styleId: id
  });

  const isBadgeInfoPresent =
    badges.length > 0 && categoryInfo?.name && !isErrorBadgeInfo;
  const isLoadingBadgeInfo = hasBadges && isLoadingBadges;

  const scrollToReviews = () => {
    setReviewsAccordionState(true);
    reviewRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <Box
      sx={{
        alignItems: {md: 'flex-start', sm: 'center'},
        display: 'flex',
        flexDirection: {md: 'column', sm: 'row'},
        flexWrap: 'wrap',
        gap: '0.25rem'
      }}>
      {isLoadingBadgeInfo && <ProductHeaderBadgesSkeleton />}
      {isBadgeInfoPresent && (
        <ProductHeaderBadges badges={badges} categoryName={categoryInfo.name} />
      )}
      <Typography
        data-testid="product-header-style-name"
        variant="heading1"
        weight="bold"
        sx={{
          fontSize: {md: '1.75rem', sm: '1.5rem'},
          lineHeight: '1.25',
          paddingTop: '0'
        }}>
        {styleName}
      </Typography>
      <Box
        data-testid="product-header-rating"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '0.125rem 0.5rem'
        }}>
        <Rating
          precision={0.1}
          value={Number(ratingValue)}
          ratingCount={ratingCount}
        />
        <ProductHeaderReviews
          isLoading={isLoading}
          totalTpReviewCount={totalTpReviewCount}
          ratingCount={ratingCount}
          onClick={() => {
            scrollToReviews();
            trackReadReviewsClickEvent();
          }}
        />
      </Box>
    </Box>
  );
}
