const baseLabUrl = `${window.location.origin}/lab`;

export const getBaseLabUrlObject = () => {
  return new URL(baseLabUrl);
};

const labLink = (colorId, preferSingles, cid, legacyStyleId) => {
  let pk = colorId;
  const link = getBaseLabUrlObject();
  if (colorId === parseInt(legacyStyleId, 10)) {
    pk = legacyStyleId;
  }

  link.searchParams.append('PK', pk);
  link.searchParams.append('prefer_singles', preferSingles);
  if (cid) link.searchParams.append('cid', cid);

  return link;
};

export default labLink;
