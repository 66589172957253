import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import {Box, Stack, Typography} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import PropTypes from 'prop-types';
import React from 'react';
import {BADGE_NAME_MAP} from './useProductBadges';

const Text = styled(Typography)({
  fontSize: '13px',
  lineHeight: '16px'
});

const Badge = ({title}) => {
  return (
    <Box
      data-testid="product-header-badge"
      sx={{
        alignItems: 'center',
        backgroundColor: designTokens.color.red.default.value,
        borderRadius: '0.5rem 0 0.5rem 0',
        display: 'flex',
        gap: '0.625rem',
        justifyContent: 'center',
        padding: '0.25rem 0.5rem',
        whiteSpace: 'nowrap'
      }}>
      <Text color="white" fontWeight={700}>
        {title}
      </Text>
    </Box>
  );
};

export default function ProductHeaderBadges({badges, categoryName}) {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
      mb="0.25rem">
      <Badge title={BADGE_NAME_MAP[badges[0]]} />
      <Stack direction="row" spacing="0.5rem" alignItems="center">
        <Text
          data-testid="product-header-badge-in-category"
          color={designTokens.color.gray.slateDefault.value}>
          in {categoryName}
        </Text>
      </Stack>
    </Stack>
  );
}

ProductHeaderBadges.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.string).isRequired,
  categoryName: PropTypes.string.isRequired
};
