import {useEffect, useMemo, useState} from 'react';
import usePriceQuote from '../../../hooks/queries/quotes';
import priceGridQuantities from '../../../utils/priceGridQuantities';

export default function usePriceBreakdownData({
  currentSelectedColor,
  productMinQty,
  specialty
}) {
  const currentMinQty = currentSelectedColor.minQty || productMinQty;

  // Memoize the quotable quantities calculation
  const quotableQuantities = useMemo(
    () =>
      priceGridQuantities(
        currentMinQty,
        currentSelectedColor.singlesEnabled,
        specialty
      ),
    [currentMinQty, currentSelectedColor.singlesEnabled, specialty]
  );

  // Use the price quote hook
  const {
    data: quotes,
    error: quotesError,
    isLoading: isQuotesLoading
  } = usePriceQuote(currentSelectedColor.id, quotableQuantities, 1, 0, true);

  // Memoize the base price calculation
  const basePrice = useMemo(
    () =>
      quotes && Object.keys(quotes).length > 0
        ? Number(Object.values(quotes)[0])
        : 0,
    [quotes]
  );

  // Keep track of last valid data to prevent UI flicker during loading states
  const [lastData, setLastData] = useState({});
  const [lastQuotableQuantities, setLastQuotableQuantities] = useState(
    () => quotableQuantities
  );

  // Process quotes data when available
  useEffect(() => {
    // Only process if quotes exists and has data
    if (quotes && Object.keys(quotes).length > 0) {
      const priceData = Object.entries(quotes).reduce(
        (acc, [quantity, price], index) => {
          acc[quantity] = {
            discountPercent:
              index === 0
                ? 0
                : Math.round(((basePrice - Number(price)) / basePrice) * 100),
            price: Number(price)
          };
          return acc;
        },
        {}
      );
      setLastData(priceData);
      setLastQuotableQuantities(quotableQuantities);
    }
    // Don't update lastData if quotes is null/undefined
  }, [quotes, basePrice, quotableQuantities]);

  // Memoize the return value to prevent unnecessary renders
  return useMemo(
    () => ({
      isError: quotesError,
      isLoading: isQuotesLoading,
      priceData: lastData,
      quotableQuantities: lastQuotableQuantities
    }),
    [quotesError, isQuotesLoading, lastData, lastQuotableQuantities]
  );
}
