import {Box, Link, Skeleton} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React from 'react';

const ReviewsLink = ({count, onClick}) => (
  <Link
    onClick={onClick}
    variant="captionRegular"
    role="link"
    tabIndex="0"
    sx={{fontSize: '0.8125rem', letterSpacing: '0', lineHeight: '1.2'}}>
    {new Intl.NumberFormat().format(count)} reviews
  </Link>
);

export default function ProductHeaderReviews({
  isLoading,
  onClick,
  totalTpReviewCount
}) {
  if (isLoading) {
    return (
      <Skeleton
        variant="text"
        width={75}
        height={16}
        data-testid="reviews-loading-skeleton"
      />
    );
  }

  if (!isLoading && totalTpReviewCount === 0) {
    return null;
  }

  return (
    <Box sx={{lineHeight: '1.25'}}>
      {isLoading && (
        <Skeleton
          variant="text"
          width={75}
          height={16}
          data-testid="reviews-loading-skeleton"
        />
      )}
      <ReviewsLink count={totalTpReviewCount} onClick={onClick} />
    </Box>
  );
}

ProductHeaderReviews.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  totalTpReviewCount: PropTypes.number.isRequired
};
