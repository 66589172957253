import {useMemo} from 'react';
import useAlgoliaQueryRule from 'hooks/queries/algoliaQueryRule';

const BADGE_RULE_ID = 'qr-conditionless-badging';

const BADGE_MAP = {
  best_seller_badge_ids: 'best_seller',
  customer_favorite_badge_ids: 'customer_favorite',
  staff_pick_badge_ids: 'staff_pick'
};

export const BADGE_NAME_MAP = {
  best_seller: 'Best Seller',
  customer_favorite: 'Customer Fave',
  staff_pick: 'Staff Pick'
};

export default function useProductBadges({categoryId, styleId}) {
  const {
    data: algoliaRule,
    isError: isErrorBadges,
    isLoading: isLoadingBadges
  } = useAlgoliaQueryRule(BADGE_RULE_ID);

  const algoliaBadgesRule = algoliaRule?.consequence?.userData?.badges || {};

  const badges = useMemo(() => {
    const selectedBadges = [];

    if (!algoliaBadgesRule) {
      return selectedBadges;
    }

    Object.entries(algoliaBadgesRule).forEach(([key, value]) => {
      const badgeType = BADGE_MAP[key];
      if (!badgeType) {
        return;
      }

      const hasBadgeTypeAssigned = value[styleId]?.includes(categoryId);
      if (!hasBadgeTypeAssigned) {
        return;
      }

      selectedBadges.push(badgeType);
    });

    return selectedBadges;
  }, [algoliaBadgesRule, categoryId, styleId]);

  return {
    badges,
    isErrorBadges,
    isLoadingBadges
  };
}
