import {Box, Link, Typography} from '@customink/pigment-react';
import React, {useContext, useRef} from 'react';
import searchParams from 'utils/url';
import {PDP_RELATED_PRODUCTS_ENTITY_TYPE} from '../../constants/pdpRelatedProducts';
import useIntersectionObserverOnce from '../../hooks/useIntersectionObserverOnce';
import addColorToStylePath from '../../utils/addColorToStylePath';
import labLink from '../../utils/labLink';
import {
  fireGA4ClickEvent,
  fireGA4DisplayEvent
} from '../../utils/metrics/pdpRelatedStyles';
import CurrentSelectedColorContext from '../Context/colorContext';
import ProductContext from '../Context/productContext';
import ProductDetailsRow from '../ProductDetailsRow';
import ProductDetailsCard from './RelatedProductCard';

const ComplementProducts = ({complementProducts}) => {
  const {currentSelectedColor} = useContext(CurrentSelectedColorContext);
  const {legacyStyleId, name, plaPricing, preferSingles} =
    useContext(ProductContext);
  const containerRef = useRef(null);

  useIntersectionObserverOnce(containerRef, () =>
    fireGA4DisplayEvent({
      entityType: PDP_RELATED_PRODUCTS_ENTITY_TYPE.complementaryStyles
    })
  );

  const handleCardClick = (styleId) => {
    CustomInk.Metrics.fireEvent({
      action: 'PDP',
      category: 'complementary styles',
      label: {name}
    });
    fireGA4ClickEvent({
      entityId: styleId,
      entityType: PDP_RELATED_PRODUCTS_ENTITY_TYPE.complementaryStyles
    });
  };

  const designCid = plaPricing
    ? null
    : searchParams().get('cid') || currentSelectedColor.loadLabCid;

  return (
    <ProductDetailsRow
      accordionSummary="Coordinating Styles"
      title="Coordinating Styles"
      childrenRef={containerRef}>
      <Typography variant="bodyLong1" className="complement-heading-body">
        To include these coordinating styles in your order, add them to your
        design in the{' '}
        <Link
          variant="inherit"
          href={labLink(
            currentSelectedColor.legacyColorId,
            preferSingles,
            designCid,
            legacyStyleId
          )}>
          Design Lab
        </Link>
        .
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: {md: '1rem', sm: '0.5rem'},
          gridTemplateColumns: {
            lg: 'repeat(4, 1fr)',
            md: 'repeat(auto-fill, minmax(200px, 1fr))',
            sm: 'repeat(auto-fill, minmax(280px, 1fr))'
          },
          mt: '1rem'
        }}>
        {complementProducts.map((complement) => (
          <ProductDetailsCard
            key={`complement-${complement.name}`}
            complimentBadge={complement.complement_badge}
            imageSrc={complement.image_src}
            imageSrcSet={complement.image_srcset}
            linkHref={addColorToStylePath(complement.link, complement.color)}
            minQty={complement.min_qty}
            name={complement.name}
            ratingCount={complement.rating_count}
            ratingScore={complement.rating_score}
            sizing={complement.sizing}
            onClick={() => handleCardClick(complement?.style_id)}
          />
        ))}
      </Box>
    </ProductDetailsRow>
  );
};

export default ComplementProducts;
