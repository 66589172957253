import {Box, Stack} from '@customink/pigment-react';
import React, {useContext, useEffect} from 'react';
import {isIframer} from 'src/utils/url';
import {pricingValuePropsPdpTest} from '../../../utils/constants/signalmanConstants';
import ProductContext from '../../Context/productContext';
import PriceBreakdown from './PriceBreakdown';
import ValuePropositionDetails from './ValuePropositionDetails';

export default function PriceAndValueProposition() {
  const {plaPricing} = useContext(ProductContext);
  const variantA = pricingValuePropsPdpTest === 'variant_a';
  const variantB = pricingValuePropsPdpTest === 'variant_b';
  const variantC = pricingValuePropsPdpTest === 'variant_c';
  const iframer = isIframer();
  const iframerOrPla = iframer || plaPricing;
  const testDisabled =
    ['excluded', 'control'].includes(pricingValuePropsPdpTest) || iframerOrPla;

  useEffect(() => {
    if (iframerOrPla) {
      return;
    }

    window.dataLayer.push({
      ab_test_group: pricingValuePropsPdpTest,
      ab_test_location: 'pdp catalog',
      ab_test_name: '2025 03 ## pdp price display',
      event: 'ab_test_group'
    });
  }, []);

  if (testDisabled) {
    return null;
  }

  return (
    <Box sx={{container: 'price-and-values / inline-size'}}>
      <Stack
        sx={{
          ...(variantC
            ? {
                '@container price-and-values (min-width: 436px)': {
                  '.value-props': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  },
                  flexDirection: 'row'
                }
              }
            : {}),
          color: 'rgba(0, 0, 0, 0.86)',
          flexDirection: 'column',
          gap: '1rem 1.5rem'
        }}>
        {variantA && <ValuePropositionDetails pricingHeader={false} />}
        {variantB && <PriceBreakdown horizontalLayout />}
        {variantC && <ValuePropositionDetails pricingHeader />}
        {variantC && <PriceBreakdown />}
      </Stack>
    </Box>
  );
}
