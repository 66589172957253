import {Rating, Typography} from '@customink/pigment-react';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React, {forwardRef, useContext, useEffect, useRef} from 'react';
import useTrustpilotReviewsCount from 'hooks/useTruspilotReviewsCount';
import CurrentSelectedColorContext from './Context/colorContext';
import ProductContext from './Context/productContext';
import ProductDetailsRow from './ProductDetailsRow';

const ProductReviews = forwardRef(function ProductReviews(_, reviewsRef) {
  const {colors, name, ratingValue} = useContext(ProductContext);
  const trustPilotRef = useRef(null);
  const isMobile = useMobileBreakpoint();

  const {reviewsAccordionState, setReviewsAccordionState} = useContext(
    CurrentSelectedColorContext
  );
  const skuList = colors?.map((color) => color.id);

  const {isLoading, totalTpReviewCount} = useTrustpilotReviewsCount(skuList);
  const hasProductReviews = totalTpReviewCount > 0;

  useEffect(() => {
    if (window.Trustpilot && trustPilotRef.current) {
      window.Trustpilot.loadFromElement(trustPilotRef.current, true);
    }
  }, [isMobile]);

  if (isLoading || !hasProductReviews) {
    return null;
  }

  return (
    <ProductDetailsRow
      title={`Reviews for ${name}`}
      desktopVersionSx={{
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.11)',
        borderRadius: '0.5rem',
        padding: '1rem'
      }}
      childrenRef={reviewsRef}
      accordionExpanded={reviewsAccordionState}
      onAccordionClick={() => setReviewsAccordionState(!reviewsAccordionState)}
      accordionSummary={
        <Typography
          variant="subHeading"
          fontWeight={600}
          sx={{alignItems: 'center', display: 'flex'}}>
          Reviews&nbsp;
          <Rating precision={0.1} value={Number(ratingValue)} />
        </Typography>
      }>
      <div
        ref={trustPilotRef}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="57177697fdb1180308e3815f"
        data-businessunit-id={process.env.TRUST_PILOT_BU_ID}
        data-style-height="700px"
        data-style-width="100%"
        data-theme="light"
        data-sku={skuList}
        data-review-languages="en"
        data-no-reviews="hide"
        data-fullwidth="true"
        data-star-color="#FA3C00"
        data-text-color="#181818"
        data-link-color="#2954C7">
        <a
          href="https://www.trustpilot.com/review/www.customink.com"
          target="_blank"
          rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </ProductDetailsRow>
  );
});

export default ProductReviews;
