import {Skeleton, Stack} from '@customink/pigment-react';
import React from 'react';

export default function ProductHeaderBadgesSkeleton() {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      mb="0.25rem"
      data-testid="badge-skeleton">
      <Skeleton
        variant="rectangular"
        width={90}
        height={23}
        sx={{borderRadius: '0.5rem 0 0.5rem 0'}}
      />
      <Skeleton variant="text" width={200} height={23} />
    </Stack>
  );
}
