import React, {useState, useRef} from 'react';
import {QueryClientProvider} from 'react-query';
import {PigmentThemeProvider, Box} from '@customink/pigment-react';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';

import {styled} from '@customink/pigment-react/lib/themeCustomink';
import useBreakpoint from 'hooks/useBreakpoint';
import queryClient from 'src/shared/queryClient';
import ProductHero from 'product_details_page/ProductHero';
import MoreInfo from 'product_details_page/MoreInfo';
import RelatedProducts from 'product_details_page/RelatedProducts';
import ProductReviews from 'product_details_page/ProductReviews';
import CurrentSelectedColorContext from 'product_details_page/Context/colorContext';
import ProductContext from 'product_details_page/Context/productContext';
import {getOctoIdFromCookie, getSessionFromCookie} from '../algolia_listing_pages/utils/data_utils';
import SampleAndQuote from 'product_details_page/ProductHero/SampleAndQuote';

const ProductHeroWrapper = styled(Box)({
  backgroundColor: 'white',
  color: '#000',
  fontSize: '16px',
  maxWidth: '100vw',
  position: 'relative',
  borderTop: '1px solid #0000001c',
  marginBottom: 0,
  '@media (min-width: 700px)': {
    maxWidth: 'none',
    borderBottom: '1px solid #0000001c',
    marginBottom: '2rem'
  }
});

const ProductDetailsPage = (props) => {
  const [currentSelectedColor, setCurrentSelectedColor] = useState(
    props.productProps.selectedColor
  );
  const [reviewsAccordionState, setReviewsAccordionState] = useState(false);
  const [similarAccordionState, setSimilarAccordionState] = useState(false);
  const reviewRef = useRef(null);
  const similarRef = useRef(null);
  const {name, productInfo} = props.productProps || {};
  const sessionToken = getOctoIdFromCookie() || getSessionFromCookie();
  window.dataLayer.push({algoliaUserToken: sessionToken});

  const breakpoint = useBreakpoint();

  return (
    <QueryClientProvider client={queryClient}>
      <PigmentThemeProvider>
        <ProductContext.Provider
          value={{
            ...props.productProps,
            mainImage: props.mainImage,
            categoryInfo: props.categoryInfo
          }}>
          <CurrentSelectedColorContext.Provider
            value={{
              currentSelectedColor,
              setCurrentSelectedColor,
              reviewsAccordionState,
              setReviewsAccordionState,
              similarAccordionState,
              setSimilarAccordionState
            }}>
            <ProductHeroWrapper>
              <ProductHero
                {...props.productProps}
                mainImage={props.mainImage}
                ttarpNumber={props.ttarpNumber}
                iframed={props.iframed}
                reviewRef={reviewRef}
                similarRef={similarRef}
                sampleQuote={props.sampleQuote}
                samplePrice={props.samplePrice}
                sampleLinkClass={props.sampleLinkClass}
                sampleLinkHref={props.sampleLinkHref}
                sampleOrderType={props.sampleOrderType}
                checkoutAction={props.checkoutAction}
                sampleQuoteCid={props.sampleQuoteCid}
                renderSampleForm={props.renderSampleForm}
                blanksOrdering={props.blanksOrdering}
                productProps={props.productProps}
              />
            </ProductHeroWrapper>
            <Box
              sx={{
                backgroundColor: {md: 'unset', sm: 'white'},
                display: 'flex',
                flexDirection: 'column',
                gap: {md: '2rem', sm: '1rem'},
                pt: {md: 0, sm: '1rem'},
                px: {md: 0, sm: '1rem'},
                pb: '1rem',
                mb: '1rem',
                borderBottom: {sm: '1px solid #0000001c', md: 'none'}
              }}>
              <ProductReviews ref={reviewRef} />
              <RelatedProducts ref={similarRef} />
              {productInfo && (
                <MoreInfo name={name} productInfo={productInfo} />
              )}
              {breakpoint === 'sm' && <SampleAndQuote ttarpNumber={props.ttarpNumber} currentColor={currentSelectedColor} />}
            </Box>
          </CurrentSelectedColorContext.Provider>
        </ProductContext.Provider>
      </PigmentThemeProvider>
    </QueryClientProvider>
  );
};

export default ProductDetailsPage;
