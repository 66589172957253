import {CheckCircleIcon, Stack, Typography} from '@customink/pigment-react';
import React from 'react';

export default function ValuePropositionItem({label}) {
  return (
    <Stack direction="row" gap="0.375rem">
      <CheckCircleIcon sx={{color: '#FA3C00', marginLeft: '-3px'}} />
      <Typography fontSize="0.875rem" lineHeight="1.25" paddingTop="4px">
        {label}
      </Typography>
    </Stack>
  );
}
