import {useQuery} from 'react-query';

const TP_API_KEY = process.env.TRUST_PILOT_API_KEY;
const TP_BU_ID = process.env.TRUST_PILOT_BU_ID;

const createTrustPilotUrl = (path) =>
  `https://api.trustpilot.com/v1/product-reviews/business-units/${TP_BU_ID}${path}?apikey=${TP_API_KEY}`;

const BASE_URL = createTrustPilotUrl('');
const IMPORTED_BASE_URL = createTrustPilotUrl('/imported-reviews-summaries');

const fetchProductReviews = async (skuList, baseUrl) => {
  const fetchUrl = `${baseUrl}&sku=${skuList}`;
  const response = await fetch(fetchUrl);

  if (!response.ok) {
    throw new Error('Failed to fetch trustpilot product reviews', {
      response,
      skuList
    });
  }
  return response.json();
};

export const useTrustPilotProductReviews = (skuList) => {
  return useQuery({
    queryFn: () => fetchProductReviews(skuList, BASE_URL),
    queryKey: ['tp_product_reviews', skuList]
  });
};

export const useImportedTrustPilotProductReviews = (skuList) => {
  return useQuery({
    queryFn: () => fetchProductReviews(skuList, IMPORTED_BASE_URL),
    queryKey: ['tp_imported_product_reviews', skuList]
  });
};
