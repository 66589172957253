import {ArrowNextIcon, Button} from '@customink/pigment-react';
import React, {useContext} from 'react';
import searchParams from 'utils/url';
import * as urlSetup from '../../../src/utils/url';
import labLink from '../../../utils/labLink';
import postMessageForLab from '../../../utils/labMessage';
import CurrentSelectedColorContext from '../../Context/colorContext';
import ProductContext from '../../Context/productContext';

const SelectProductOrDesignButton = ({iframed, singles, sizes, sizesList}) => {
  const {
    categoryInfo,
    decoration,
    id,
    legacyStyleId,
    minQty,
    name,
    plaPricing,
    primaryCategoryId
  } = useContext(ProductContext);

  const {currentSelectedColor: currentColor} = useContext(
    CurrentSelectedColorContext
  );

  const designCid = plaPricing
    ? null
    : searchParams().get('cid') || currentColor.loadLabCid;

  const href = labLink(
    currentColor.legacyColorId,
    singles,
    designCid,
    legacyStyleId
  );

  const handleLabLinkIframe = (e) => {
    e.preventDefault();
    const productInfo = {
      decoration,
      defaultCategoryId: primaryCategoryId,
      minQty,
      name: currentColor.name,
      productId: currentColor.legacyColorId,
      size_range: `${sizes[0].conciseName}-${sizes[sizes.length - 1].conciseName}`,
      sizes: sizesList.join(', ')
    };
    const payload = {
      categoryPath: categoryInfo.path,
      cid: currentColor.loadLabCid,
      colorId: urlSetup.getQueryParam('PK', href),
      data: [
        currentColor.id,
        productInfo,
        id,
        name,
        categoryInfo.id,
        categoryInfo.name
      ],
      event: 'catalog:product-selected',
      keywords: urlSetup.getQueryParam('KW', href),
      singles,
      styleId: id
    };
    postMessageForLab(payload);
  };

  const handleClickToLab = (e) => {
    e.preventDefault();
    window.dataLayer.push({
      click_link: 'Start Designing',
      click_section: 'pdp',
      event: 'start_designing',
      product_style_id: id,
      search_term: urlSetup.getQueryParam('keyword', href)
    });
    window.location.href = href;
  };

  const buttonProps = {
    'data-testid': 'select-product-or-start-design',
    fullWidth: true,
    size: 'large',
    ...(iframed
      ? {
          'data-href': href,
          href: '#',
          onClick: handleLabLinkIframe
        }
      : {
          href: href?.toString(),
          onClick: handleClickToLab
        })
  };
  const buttonValue = () => {
    if (iframed) return 'Select Product';
    return 'Start Designing';
  };

  return (
    <Button {...buttonProps}>
      {buttonValue()}
      <ArrowNextIcon sx={{marginLeft: '0.5rem'}} />
    </Button>
  );
};

export default SelectProductOrDesignButton;
