import {Box, Skeleton, Stack, Typography} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import {useBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React, {useContext} from 'react';
import CurrentSelectedColorContext from '../../Context/colorContext';
import ProductContext from '../../Context/productContext';
import usePriceBreakdownData from './usePriceBreakdownData';

const PriceText = styled(Typography)({
  lineHeight: '1.25'
});

const PriceTextHead = styled(PriceText)({
  fontSize: '0.875rem',
  fontWeight: '700'
});

const PriceTextBody = styled(PriceText)({
  alignContent: 'center',
  fontSize: '1rem'
});

const DiscountText = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'highlighted'
})(({highlighted = false}) => ({
  color: '#e95420',
  fontSize: '0.8125rem',
  fontStyle: 'italic',
  fontWeight: highlighted ? '600' : '500',
  whiteSpace: 'nowrap'
}));

const PriceRow = styled(Stack)(({index}) => ({
  backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'transparent',
  borderRadius: '0.25rem',
  flexDirection: 'row',
  gap: '0.5rem',
  padding: '0.25rem 1rem 0.25rem 0.5rem'
}));

// Add new styled components for horizontal layout
const HorizontalPriceContainer = styled(Box)({
  backgroundColor: '#f9f9f9',
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  padding: '0.75rem 1.5rem'
});

const HorizontalPriceRow = styled(Stack)({
  flexDirection: 'row',
  gap: '1rem',
  justifyContent: 'space-between'
});

const PriceColumn = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center'
});

// Extract the price display logic into a reusable component
const PriceItem = ({price}) => (
  <PriceTextBody>
    {price &&
      price.toLocaleString('en-US', {
        currency: 'USD',
        style: 'currency'
      })}
  </PriceTextBody>
);

// Extract discount display logic
const DiscountItem = ({discountPercent, highlighted = false}) =>
  discountPercent ? (
    <DiscountText highlighted={highlighted}>
      {discountPercent}% off
    </DiscountText>
  ) : null;

// Extract quantity display with conditional minimum label
const QuantityLabel = ({index, isHorizontal = false, quantity}) => (
  <PriceTextBody width={isHorizontal ? undefined : 96}>
    {quantity}
    {index === 0 && (
      <Box component="span" fontSize="0.8125rem" ml="0.25rem">
        ({isHorizontal ? 'min' : 'minimum'})
      </Box>
    )}
  </PriceTextBody>
);

// Extract the footer with pricing disclaimer
const PricingFooter = ({isProductScreenPrint, sx = {}}) => (
  <Box
    sx={{
      color: 'rgba(0, 0, 0, 0.57)',
      fontSize: '0.8125rem',
      lineHeight: '1.25',
      mt: '0.375rem',
      ...sx
    }}>
    * Prices shown based on {isProductScreenPrint && '1-color,'} 1&#8209;side
    design
  </Box>
);

// Create a utility function for loading styles
const getLoadingStyles = (isLoading) => ({
  fontStyle: isLoading ? 'italic' : 'normal',
  opacity: isLoading ? 0.5 : 1
});

// PriceContent component to replace the renderPriceContent function
const PriceContent = ({isHorizontal = false, priceData, quantity}) => {
  if (priceData[quantity]) {
    return (
      <>
        <PriceItem price={priceData[quantity].price} />
        <DiscountItem
          discountPercent={priceData[quantity].discountPercent}
          highlighted={!isHorizontal}
        />
      </>
    );
  }

  return isHorizontal ? (
    <Stack alignItems="center" justifyContent="center">
      <Skeleton variant="text" width="2.5rem" height="1.5rem" />
      <Skeleton variant="text" width="2rem" height="1rem" />
    </Stack>
  ) : (
    <Skeleton variant="text" width="6.25rem" height="1.25rem" />
  );
};

// Horizontal layout component
const HorizontalLayout = ({
  isProductScreenPrint,
  loadingStyles,
  priceData,
  quotableQuantities
}) => (
  <Box>
    <PriceTextHead>Pricing:</PriceTextHead>
    <HorizontalPriceContainer>
      <HorizontalPriceRow>
        {quotableQuantities.map((quantity, index) => (
          <PriceColumn key={quantity} sx={loadingStyles} gap="0.125rem">
            <QuantityLabel quantity={quantity} index={index} isHorizontal />
            <PriceContent
              quantity={quantity}
              priceData={priceData}
              isHorizontal
            />
          </PriceColumn>
        ))}
      </HorizontalPriceRow>
    </HorizontalPriceContainer>
    <PricingFooter isProductScreenPrint={isProductScreenPrint} />
  </Box>
);

// Vertical layout component
const VerticalLayout = ({
  isProductScreenPrint,
  loadingStyles,
  priceData,
  quotableQuantities
}) => (
  <Box sx={{display: 'flex', flex: 1.25, flexDirection: 'column'}}>
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      padding="0 1rem 0.25rem 0.5rem"
      gap="0.5rem">
      <PriceTextHead width={96}>Quantity</PriceTextHead>
      <PriceTextHead>Price per item</PriceTextHead>
    </Box>
    {quotableQuantities.map((quantity, index) => (
      <PriceRow
        key={quantity}
        index={index}
        spacing={0}
        height={28}
        sx={loadingStyles}>
        <QuantityLabel quantity={quantity} index={index} />
        {priceData[quantity] ? (
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            gap="0.25rem"
            justifyContent="space-between"
            maxWidth="8.75rem"
            sx={loadingStyles}
            alignItems="baseline">
            <PriceContent quantity={quantity} priceData={priceData} />
          </Box>
        ) : (
          <PriceContent quantity={quantity} priceData={priceData} />
        )}
      </PriceRow>
    ))}
    <PricingFooter
      isProductScreenPrint={isProductScreenPrint}
      sx={{
        display: 'flex',
        paddingLeft: '0.5rem',
        textIndent: '-0.5rem'
      }}
    />
  </Box>
);

export default function PriceBreakdown({horizontalLayout = false}) {
  const {
    decoration_method: decorationMethod,
    minQty,
    specialty
  } = useContext(ProductContext);
  const {currentSelectedColor} = useContext(CurrentSelectedColorContext);
  const {isError, isLoading, priceData, quotableQuantities} =
    usePriceBreakdownData({
      currentSelectedColor,
      minQty,
      specialty
    });

  const isMdScreen = useBreakpoint('md');
  const isSmScreen = useBreakpoint('sm');
  const isSmallScreen = isMdScreen || isSmScreen;
  const showHorizontalLayout = horizontalLayout && !isSmallScreen;

  const isProductScreenPrint = decorationMethod === 'screen';
  const loadingStyles = getLoadingStyles(isLoading);

  if (isError || quotableQuantities.length === 0) {
    return null;
  }

  return showHorizontalLayout ? (
    <HorizontalLayout
      quotableQuantities={quotableQuantities}
      loadingStyles={loadingStyles}
      priceData={priceData}
      isProductScreenPrint={isProductScreenPrint}
    />
  ) : (
    <VerticalLayout
      quotableQuantities={quotableQuantities}
      loadingStyles={loadingStyles}
      priceData={priceData}
      isProductScreenPrint={isProductScreenPrint}
    />
  );
}
