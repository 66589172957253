import {useQuery} from 'react-query';
import {initializeAlgoliaClient} from 'algolia_listing_pages/utils/algolia_client';
import AlgoliaIndexMap from '../../algolia_listing_pages/constants/AlgoliaIndexMap';

const algoliaClient = initializeAlgoliaClient();
const env = process.env.RAILS_ENV || 'staging';
const indexName = `${AlgoliaIndexMap[env]}_styles`;
const index = algoliaClient.initIndex(indexName);

const fetchAlgoliaRule = async (ruleId) => {
  return index.getRule(ruleId);
};

export default function useAlgoliaQueryRule(ruleId) {
  return useQuery({
    cacheTime: 24 * 60 * 60 * 1000, // 24 hours
    queryFn: () => fetchAlgoliaRule(ruleId),
    queryKey: ['algoliaRule', ruleId],
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000 // 1 hour
  });
}
