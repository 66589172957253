import {Box, Stack, Typography} from '@customink/pigment-react';
import React from 'react';
import GuaranteeStampIcon from './GuaranteeStampIcon';
import ValuePropositionItem from './ValuePropositionItem';

export default function ValuePropositionDetails({pricingHeader = true}) {
  return (
    <Box
      className="value-props"
      sx={{
        alignItems: 'center',
        backgroundColor: 'rgba(250, 60, 0, 0.09)',
        borderRadius: '0.5rem',
        container: 'value-props / inline-size',
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        gap: '1rem',
        height: '100%',
        padding: '1rem'
      }}>
      <Stack direction="column" gap="0.25rem">
        {pricingHeader && (
          <Typography fontSize="1rem" lineHeight="1.25" fontWeight="700">
            All-inclusive pricing includes:
          </Typography>
        )}
        <Box
          sx={{
            '@container value-props (min-width: 425px)': {
              gridTemplateColumns: '190px 1fr'
            },
            display: 'grid',
            gap: '0.375rem',
            gridTemplateColumns: '1fr'
          }}>
          <ValuePropositionItem label="FREE design review" />
          <ValuePropositionItem label="FREE standard shipping" />
          <ValuePropositionItem label="No setup fees" />
          <Box
            sx={{
              '@container value-props (max-width: 305px)': {
                display: 'flex'
              },
              '@container value-props (min-width: 425px)': {
                display: 'flex'
              },
              display: 'none'
            }}>
            <ValuePropositionItem label="100% satisfaction guarantee" />
          </Box>
        </Box>
      </Stack>
      <Box
        sx={{
          '@container value-props (max-width: 305px)': {
            display: 'none'
          },
          '@container value-props (min-width: 425px)': {
            display: 'none'
          },
          display: 'flex',
          flex: '1',
          justifyContent: 'center',
          marginY: '-8px',
          maxHeight: pricingHeader ? '110px' : '96px',
          minWidth: '28%'
        }}>
        <GuaranteeStampIcon />
      </Box>
    </Box>
  );
}
