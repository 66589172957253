import {Box} from '@customink/pigment-react';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import useBreakpoint from 'hooks/useBreakpoint';
import useImagePreloader from 'hooks/useImagePreloader';
import * as urlSetup from 'src/utils/url';
import rollbar from 'src/vendor/rollbar';
import {trackOrderFewerThanSixEvent} from 'utils/metrics/productDetailsMetrics';
import {getIxParams} from '../../utils/design/ixParams';
import {
  getColorImage,
  getYdhParams,
  mmsImageFingerprint
} from '../../utils/images';
import postMessageForLab from '../../utils/labMessage';
import CurrentSelectedColorContext from '../Context/colorContext';
import EmployeeBlanks from '../EmployeeBlanks';
import ProductColorsAndSizes from '../ProductColorsAndSizes';
import DeliveryOptions from './DeliveryOptions';
import ElevateSuperRushDelivery from './ElevateSuperRushDelivery';
import PLAForm from './PLAForm';
import PlaPricing from './PlaPricing';
import PriceAndValueProposition from './PriceAndValueProposition/PriceAndValueProposition';
import ProductCarousel from './ProductCarousel';
import ProductCTA from './ProductCTA';
import ProductDescription from './ProductDescription';
import ProductDetails from './ProductDetails';
import ProductHeader from './ProductHeader/ProductHeader';
import SampleAndQuote from './SampleAndQuote';

const ProductHero = ({
  blanksOrdering,
  checkoutAction,
  colorLimit,
  colors,
  decoration_method: decorationMethod,
  hasSingles,
  id,
  iframed,
  mainImage,
  material,
  preferSingles,
  primaryCategoryId,
  renderSampleForm,
  reviewRef,
  sampleLinkClass,
  sampleLinkHref,
  sampleOrderType,
  samplePrice,
  sampleQuote,
  sampleQuoteCid,
  selectedColor,
  sizingText,
  ttarpNumber
}) => {
  const {currentSelectedColor, setCurrentSelectedColor} = useContext(
    CurrentSelectedColorContext
  );

  const [currentColor, setCurrentColor] = useState(selectedColor);
  const [singles, setSingles] = useState(preferSingles);
  const sizes = currentColor?.sizes.filter((size) => size.status === 'active');
  const sizesList = sizes?.map((size) => size.conciseName);
  const breakpoint = useBreakpoint();
  const preloadImage = useImagePreloader();
  const firstRender = useRef(true);
  const isMobile = useMobileBreakpoint();

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  });

  const updateUrl = (color) => {
    if (color.id) {
      let url = window.location.href;
      url = urlSetup.setQueryParam('PK', color.id, url);
      window.history.replaceState({}, '', url);
    }
  };

  const updateDataLayer = (value) => {
    const swatchData =
      document.querySelector('.pc-Style-dataLayer')?.dataset || {};
    if (value) {
      swatchData.variant = value.name;
      swatchData.colorid = value.id;
      swatchData.colorurl = `${urlSetup.baseUrl()}?PK=${value.id}`;
      swatchData.colorimage = `https://mms-images.out.customink.com/mms/images/catalog/colors/${value.id}/views/alt/front_small_extended.png`;
    }
    return ProductCatalog.gtmEvent(
      'detailImpression',
      'detailImpression',
      swatchData
    );
  };

  const changeColor = (value) => {
    if (value) {
      setCurrentColor(value);
      setCurrentSelectedColor(value);
      updateUrl(value);
      updateDataLayer(value);
    }
  };

  document.addEventListener('DOMContentLoaded', postMessageForLab('domReady'));

  const imageFingerprint = mmsImageFingerprint({
    style_card_image_url: mainImage[currentColor.id].src
  });

  const buildColorImage = (color, width = 412) => {
    const ixParams = getIxParams(
      {},
      primaryCategoryId,
      mainImage[color.id].src
    );
    const ydhParams = getYdhParams(mainImage[color.id].src);
    ixParams.ixw = width;
    ixParams.ixbg = '#f5f5f5';
    const colorImage = getColorImage(
      color.id,
      {
        color_limit: colorLimit,
        decoration_method: decorationMethod,
        imageFingerprint,
        material,
        primary_category_id: primaryCategoryId,
        style_card_image_url: mainImage[color.id].src
      },
      imageFingerprint,
      {...ixParams, ...ydhParams, ...{size: 'large'}},
      false,
      iframed,
      true,
      false
    );
    return colorImage;
  };

  const handlePreloadImage = (color) => {
    preloadImage(buildColorImage(color));
  };

  const handleSetSingles = () => {
    setSingles(!singles);
    trackOrderFewerThanSixEvent(!singles);
    let url = window.location.href;
    if (!singles) {
      url = urlSetup.setQueryParam('quote_qty', 'min', url);
    } else {
      url = urlSetup.deleteQueryParam('quote_qty', url);
    }
    window.history.replaceState({}, '', url);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {md: 'row', sm: 'column'},
        gap: {md: '1rem', sm: '0', xl: '2rem'},
        margin: 'auto',
        marginTop: {lg: '2rem', md: '1.5rem', sm: 0},
        maxWidth: {sm: 'unset', xl: '80.25rem'},
        padding: {md: '0 1rem', sm: '0'}
      }}>
      <ProductCarousel buildColorImage={buildColorImage} />
      <Box
        sx={{
          display: 'flex',
          flex: {lg: '5.5', sm: '1'},
          flexDirection: 'column',
          gap: '1rem',
          maxWidth: {
            lg: '44%',
            md: '48%',
            sm: '100%',
            xl: '40%'
          },
          padding: {md: '0.5rem 0 2.5rem 0', sm: '1rem 1rem 0'}
        }}>
        <ProductHeader reviewRef={reviewRef} />
        <ElevateSuperRushDelivery styleId={id} noWrap />
        <ProductColorsAndSizes
          colors={colors}
          currentColor={currentColor}
          changeColor={changeColor}
          hasSingles={hasSingles}
          toggleSingles={handleSetSingles}
          singles={singles}
          sizingText={sizingText}
          preloadImage={handlePreloadImage}
          renderSampleForm={renderSampleForm}
          breakpoint={breakpoint}
        />
        <ProductCTA
          iframed={iframed}
          singles={singles}
          sizes={sizes}
          sizesList={sizesList}
        />
        {renderSampleForm && (
          <PLAForm
            checkoutAction={checkoutAction}
            currentSelectedColor={currentSelectedColor}
            sampleLinkClass={sampleLinkClass}
            sampleLinkHref={sampleLinkHref}
            sampleOrderType={sampleOrderType}
            samplePrice={samplePrice}
            sampleQuote={sampleQuote}
            sampleQuoteCid={sampleQuoteCid}
            sizes={sizes}
            sizesList={sizesList}
          />
        )}
        {!renderSampleForm && <PlaPricing />}
        <PriceAndValueProposition />
        <ProductDescription />
        <ProductDetails />
        <DeliveryOptions />
        {!isMobile && (
          <SampleAndQuote
            ttarpNumber={ttarpNumber}
            currentColor={currentColor}
          />
        )}
        {blanksOrdering && (
          <EmployeeBlanks currentColor={currentColor} availableSizes={sizes} />
        )}
      </Box>
    </Box>
  );
};

const ProductHeroWithErrorBoundary = withErrorBoundary(ProductHero, {
  fallback: <div>Something went wrong!</div>,
  onError: (error, componentStack) => {
    rollbar.error(error, componentStack);
  }
});

export default ProductHeroWithErrorBoundary;
