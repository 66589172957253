import {Box, Divider, Typography} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import PropTypes from 'prop-types';
import React from 'react';

const ListItem = styled(Typography)({
  ':first-of-type': {
    paddingLeft: 0
  },
  display: 'inline-block',
  fontSize: '1rem',
  textAlign: 'center'
});

const StyledDivider = styled(Divider)({
  borderColor: '#E5E5E5',
  height: '1.25rem',
  margin: '0'
});

const InactiveListItem = ({size}) => {
  const isSmall = size.conciseName.length === 1;
  return (
    <ListItem
      sx={{
        '&:before': {
          borderTop: '1px solid red',
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'rotate(-30deg)',
          ...(isSmall && {
            left: '-0.25rem',
            right: '-0.25rem'
          }),
          ...(!isSmall && {
            left: 0,
            right: 0
          })
        },
        color: '#c6c6c6',
        position: 'relative'
      }}
      variant="bodyShort1">
      {size.conciseName}
    </ListItem>
  );
};

export default function SizeList({
  compact = false,
  oneSize = false,
  sizes = []
}) {
  if (!sizes || sizes.length === 0) {
    return null;
  }

  const firstSize = sizes[0];
  const lastSize = sizes[sizes.length - 1];

  const getSizeRange = () => {
    if (oneSize || firstSize.conciseName === lastSize.conciseName) {
      return firstSize.conciseName;
    }
    return `${firstSize.conciseName} - ${lastSize.conciseName}`;
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem',
        justifyContent: 'flex-start'
      }}>
      {compact ? (
        <ListItem variant="bodyShort1">{getSizeRange()}</ListItem>
      ) : (
        sizes.map((size, index) => (
          <React.Fragment key={size.name}>
            {index > 0 && <StyledDivider orientation="vertical" flexItem />}
            {size.status !== 'inactive' ? (
              <ListItem variant="bodyShort1">{size.conciseName}</ListItem>
            ) : (
              <InactiveListItem size={size} />
            )}
          </React.Fragment>
        ))
      )}
    </Box>
  );
}

SizeList.propTypes = {
  compact: PropTypes.bool,
  oneSize: PropTypes.bool,
  sizes: PropTypes.arrayOf(PropTypes.object).isRequired
};
